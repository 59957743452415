<template>
  <div>
    <team-list-add-new :is-add-new-team-sidebar-active.sync="isAddNewTeamSidebarActive" @refetch-data="refetchData">
    </team-list-add-new>


    <b-card no-body class="mb-0">

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="搜尋..." />
              <b-button variant="primary" @click="addTeam" v-if="ability.can('create', 'teams')">
                <span class="text-nowrap">新增團隊</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table ref="refTeamListTable" class="position-relative" :items="fetchTeams" responsive :fields="tableColumns"
        primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc">
        <!-- Column: TeamLeader -->
        <template #cell(teamleader)="data">
          <b-badge v-for="user in data.item.users.filter(user => user.is_teamleader)" :key="user.name_en"
            :variant="`light-primary`" class="text-capitalize" style="padding:10px;">
            {{ user.name_en }}
          </b-badge>

        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>

            <b-dropdown-item v-if="ability.can('update', 'teams')"
              :to="{ name: 'teams-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">修改</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="ability.can('delete', 'teams')" @click="confirmDelete(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">刪除</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}
              entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

            <b-pagination v-model="currentPage" :total-rows="totalTeams" :per-page="perPage" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

  </div>

</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import axiosIns from "@/libs/axios";
import teamStoreModule from "../teamStoreModule";
import useTeamsList from "./useTeamsList";
import TeamListAddNew from "./TeamListAddNew.vue"

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    TeamListAddNew
  },
  methods: {
    editMenu(id) {

    },
    confirmDelete(id) {
      this.$swal({
        title: '確認刪除?',
        text: 'Your action is final and you will not be able to retrieve the team.',
        showCancelButton: true,
        confirmButtonText: '刪除',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$store.dispatch("app-team/deleteTeam", { 'team_id': id })
              .then((response) => {
                this.$swal({
                  text: response.data.message,
                  icon: 'success',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  showClass: {
                    popup: 'animate__animated animate__bounceIn',
                  },
                  buttonsStyling: false,
                })
                this.refetchData()
              }).catch((error) => {
                this.$swal({
                  text: JSON.stringify(error.response.data.message),
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  showClass: {
                    popup: 'animate__animated animate__bounceIn',
                  },
                  buttonsStyling: false,
                })

              })
          }
        })
    },
    addTeam() {
      this.isAddNewTeamSidebarActive = true;
    }
  },
  setup() {
    const APP_TEAM_STORE_MODULE_NAME = "app-team";
    const isAddNewTeamSidebarActive = ref(false);

    if (!store.hasModule(APP_TEAM_STORE_MODULE_NAME))
      store.registerModule(APP_TEAM_STORE_MODULE_NAME, teamStoreModule);

    const {
      fetchTeams,
      tableColumns,
      perPage,
      currentPage,
      totalTeams,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      refTeamListTable,
      ability,
    } = useTeamsList();

    return {
      isAddNewTeamSidebarActive,
      fetchTeams,
      tableColumns,
      perPage,
      currentPage,
      totalTeams,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      refTeamListTable,
      ability,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.badge {
  display: block;
  margin: 5px;
  max-width: 200px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
