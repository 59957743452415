<template>
  <b-sidebar
    id="add-new-team-sidebar"
    :visible="isAddNewTeamSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-team-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Add New Team</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <validation-observer 
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Team Name-->
          <validation-provider
            #default="validationContext"
            name="Team Name"
            rules="required"
          >
            <b-form-group label="Team Name" label-for="team-name">
              <b-form-input
                id="team-name"
                v-model="teamData.team_name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Team Name"
              >
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-input>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Team Description"
            rules="required"
          >
            <b-form-group label="Team Description" label-for="team-description">
              <b-form-textarea
                id="team-description"
                v-model="teamData.team_description"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormTextarea,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewTeamSidebarActive",
    event: "update:is-add-new-team-sidebar-active",
  },
  props: {
    isAddNewTeamSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup(props, { emit }) {

    const blankTeamData = {
      id: 0,
      team_name: "",
      team_description: "",
    }

    const teamData = ref(JSON.parse(JSON.stringify(blankTeamData)))
    const resetteamData = () => {
      teamData.value = JSON.parse(JSON.stringify(blankTeamData))
    };

    const toast = useToast()

    const onSubmit = () => {
      store.dispatch("app-team/addTeam", teamData.value)
      .then((response) => {
        emit("update:is-add-new-team-sidebar-active", false);
        emit('refetch-data')
        console.log(response)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            text: response.data.message,
            icon: 'CheckIcon',
            variant: 'success'
          }
        })
      })
      .catch((error) => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Fail',
            text: error.response.data.message,
            icon: 'HeartIcon',
            variant: 'danger'
          }
        })
      })
      
      ;
      
    };

    const { 
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetteamData)

    return {
      teamData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>