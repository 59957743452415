import { render, staticRenderFns } from "./TeamsList.vue?vue&type=template&id=99893a8c&scoped=true&"
import script from "./TeamsList.vue?vue&type=script&lang=js&"
export * from "./TeamsList.vue?vue&type=script&lang=js&"
import style0 from "./TeamsList.vue?vue&type=style&index=0&id=99893a8c&lang=scss&scoped=true&"
import style1 from "./TeamsList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99893a8c",
  null
  
)

export default component.exports