import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '@/libs/acl/ability'

export default function useTeamsList(){

    const toast = useToast()

    const refTeamListTable = ref(null)
    
    // Teams table contains column Team Code / Team Name / Status / Created at / Action
    const tableColumns = [
        { key: 'id', label:'團隊編號',sortable: true },
        { key: 'team_name', label: '團隊名稱',sortable: true },
        { key: 'team_description', label: '團隊介紹'},
        { key: 'teamleader', 'label': '團隊隊長'},
        { key: 'actions', 'label': '操作'}
    ]
    const perPage = ref(10)
    const totalTeams = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)

    const dataMeta = computed(() => {
        const localItemsCount = refTeamListTable.value ? refTeamListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1: 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalTeams.value,
        }
    })

    const refetchData = () =>{
        refTeamListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery], () => {
        refetchData()
    })

    const fetchTeams = (ctx, callback) => {

        store.dispatch('app-team/fetchTeams', {
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
        })
        .then(response => {
            const { teams, total } = response.data
            callback(teams)
            totalTeams.value = total
        })
        .catch(() => {
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error fetching teams list',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                },
            })
        })

    }

    return {
        fetchTeams,
        tableColumns,
        perPage,
        currentPage,
        totalTeams,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refetchData,
        refTeamListTable,
        ability

    }


}